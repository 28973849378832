import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import { Link } from "gatsby";
import SEO from "../components/seo/Seo";
import { Container, Row, Col } from "react-bootstrap";
import { Waypoint } from "react-waypoint";

import "../styles/using-a-zipped-website.scss";

const UsingaZippedWebsite = () => {
  useEffect(() => {});

  return (
    <Layout>
      <SEO title="Using a Zipped Website" />
      <div className="using-a-zipped-website" itemprop="text">
        <Container className="hero-section">
          <Row className="blue-section">
            <h1>How to use a zipped website</h1>
            <p className="hero-p">
              This walkthrough will give you a high level overview of how to
              install a zipped website. Depending on who you host with, the
              steps might be a little different.
            </p>
          </Row>
        </Container>
        <Container className="main-container">
          <h2>1. Install a new instance of WordPress</h2>
          <h2>2. Import the database</h2>
          <p>Go to phpMyAdmin underyour new install to open the sql database</p>
          <h3>SELECT PRODUCTION DATABASE</h3>
          <p>Click on the database without ‘snapshot’ in the name.</p>
          <p>
            <img
              className="zipped-images"
              src={require("../images/using-a-zipped-website/zip-img1.png")}
              alt=""
            />
          </p>

          <h3>Drop the tables</h3>
          <p>
            Before importing the database, the current database has to be
            emptied of its content. Click on “Check All’ to select all of the
            database tables, then from the drop down menu select ‘Drop.’
          </p>
          <p>
            <img
              className="zipped-images"
              src={require("../images/using-a-zipped-website/zip-img2.png")}
              alt=""
            />
          </p>

          <p>
            On the following screen confirm with a ‘Yes.’ All of the database
            tables will now disappear.
          </p>
          <h3>Import Database</h3>
          <p>
            Now that the tables are dropped, you are ready to import the
            database to phpMyAdmin. Click on the ‘Import’ tab in the menu. Next,
            click on ‘Choose File’ and select your database export file.
            Finally, click on ‘Go’ at the bottom of the page.
          </p>
          <p>If successful, you will now see the new tables in the database.</p>
          <p>
            <img
              className="zipped-images"
              src={require("../images/using-a-zipped-website/zip-img3.png")}
              alt=""
            />
          </p>
          <h2>3. Upload wp-content</h2>
          <p>
            FTP into your site and upload your wp-content folder, making sure to
            overwrite the existing wp-content folder.
          </p>
          <h2>4. Update database with new URL</h2>
          <h3>Select ‘wp_options’ table</h3>
          <p>
            Go back to phpMyAdmin and find the corresponding database, the one
            not labeled snapshot. The database contains a set of tables which
            store a collection of values. Locate the ‘wp_options’ table and
            click on it.
          </p>
          <p>
            <img
              className="zipped-images"
              src={require("../images/using-a-zipped-website/zip-img4.png")}
              alt=""
            />
          </p>
          <h3>Select ‘Siteurl’ option</h3>
          <p>
            The table contains a collection of options and values. Locate the
            option ‘siteurl’ and click on ‘edit’ in the row where you see the
            option.
          </p>
          <p>
            <img
              className="zipped-images"
              src={require("../images/using-a-zipped-website/zip-img5.png")}
              alt=""
            />
          </p>
          <h3>Edit ‘Siteurl’ Option Value</h3>
          <p>
            Edit the ‘siteurl’ value to be that of your domain. Then click on
            ‘Go’ at the bottom of the page to save the changes.
          </p>
          <p>
            <img
              className="zipped-images"
              src={require("../images/using-a-zipped-website/zip-img6.png")}
              alt=""
            />
          </p>
          <h3>Select ‘Home’ Option</h3>
          <p>
            Go back to the ‘wp_options’ table, locate the ‘home’ option, and
            click on ‘edit’ in the same row.
          </p>
          <h3>Edit ‘Home’ Option Value</h3>
          <p>
            Edit the ‘home’ value to be your domain as well. Then click on ‘Go’
            at the bottom of the page to save the changes.
          </p>
          <h2>5. Change the password forthe user “multipage”</h2>
          <p>
            Also in phpMyAdmin should be a table called wp_users. Click edit on
            that to view the current users in your database. You should have a
            wpengine user and a multipage user. From there, click to edit the
            multipage user.
          </p>
          <h2>
            <img
              className="zipped-images"
              src={require("../images/using-a-zipped-website/zip-img7.png")}
              alt=""
            />
          </h2>
          <h3>Edit ‘User_pass’ Option Value</h3>
          <p>
            From the “Function” dropdown of “user_pass”, select “MD5”. Then,
            enter your preferred password in the “Value” space.
          </p>
          <p>
            <img
              className="zipped-images"
              src={require("../images/using-a-zipped-website/zip-img8.png")}
              alt=""
            />
          </p>
          <h2>6. Login to wordpress admin</h2>
          <p>
            You should now be able to navigate to [yourdomain].com/wp-admin to
            login to the back end of your site. Your username is “multipage” and
            your password is whatever you changed it to in the previous step.
          </p>
        </Container>
      </div>
    </Layout>
  );
};

export default UsingaZippedWebsite;
